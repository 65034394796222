import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// 使用插件
dayjs.extend(utc);
dayjs.extend(timezone);

const getLocalTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatTimestamp = (timestamp: number): string => {
  const timezone = getLocalTimezone(); // 自动获取本机时区
  return dayjs.unix(timestamp).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
};

export const formatToPercentage = (
  decimal: number | string,
  decimalPlaces = 2
) => {
  const value = Number(decimal);

  // 将小数转换为百分数
  const percentage = (value * 100).toFixed(decimalPlaces);
  return `${percentage}%`;
};

export const formatNumber = (num: number) => {
  return new Intl.NumberFormat().format(num);
};

export const address2Desensitize = (address: string | undefined, len: number = 2) => {
  if (!address) return address;
  const reg = new RegExp(`\(\\w{${len}}\)\\w*\(\\w{${len}}\)`);
  return address.replace(reg, '$1...$2');
}
