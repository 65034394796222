export default[
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function decimals()  view  returns (uint8)',
  'function totalSupply() view returns (uint256)',
  'function approve(address spender, uint256 value) external returns (bool)',
  'function balanceOf(address account) external view returns (uint256)',
  'function allowance(address owner, address spender) external view returns (uint256)',
  'function transfer(address, uint) public returns (bool)',
]
