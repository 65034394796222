import { mttChain } from "../chain/mtt"
import { ethChain } from "../chain/eth"
import { bscChain } from "../chain/bsc"


interface IChain {
  id: number | string
  name: string
  symbol: string
  icon: string
}
// ==================== 基础链信息 ==================== \\
const MTT: IChain = {
  id: mttChain.id,
  name: mttChain.name,
  symbol: mttChain.nativeCurrency.symbol,
  icon: "https://cdn-mttweb.mtt.xyz/mid/erc20-token/i0rFkjR6.png",
}


const ETH: IChain = {
  id: ethChain.id,
  name: ethChain.name,
  symbol: ethChain.nativeCurrency.symbol,
  icon: "https://cdn-mttweb.mtt.xyz/mid/erc20-token/8VOUgDG5.png",
}

const Osmosis: IChain = {
  id: "osmosis-1",
  name: "Osmosis",
  symbol: "OSMO",
  icon: "https://static-s3.mtt.xyz/chain-website/Chain--32%2520%281%29_e6eaf3b06.png",
}

const BSC: IChain = {
  id: bscChain.id,
  name: bscChain.name,
  symbol: bscChain.nativeCurrency.symbol,
  icon: "https://cdn-mttweb.mtt.xyz/mid/erc20-token/clINPQE9.webp"
}




export interface IBridgeChain extends IChain {
  label: string
  value: string | number
}
// ==================== bridge 链信息 ==================== \\
export const MTT_EVM: IBridgeChain = {
  ...MTT,
  value: MTT.id,
  label: "MTT Network (evm)",
}
export const MTT_COS: IBridgeChain = {
  ...MTT,
  id: "mtt_6880-1",
  value: "mtt_6880-1",
  label: "MTT Network (cosmos)"
}
export const ETH_EVM: IBridgeChain = {
  ...ETH,
  value: ETH.id,
  label: ETH.name
}
export const OSMO_COS: IBridgeChain = {
  ...Osmosis,
  value: Osmosis.id,
  label: "Osmosis (cosmos)"
}
export const BNB_BSC: IBridgeChain = {
  ...BSC,
  value: BSC.id,
  label: BSC.name
}
// 基本的链列表
export const baseChainList: IBridgeChain[] = [BNB_BSC, MTT_EVM, OSMO_COS, MTT_COS, ETH_EVM]