import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { QueryClient } from '@tanstack/react-query';
import { setGrpcEnv, EnvKey } from "@mtt/grpc-core";
import { newsServiceMgrNoAuth } from '../grpc/news.service'
import "../web3/initAppkit"

if (typeof window === 'undefined') {
  setGrpcEnv(EnvKey.Production);
} else {
  const env = window.location.host.endsWith('mtt.network') ? EnvKey.Production : EnvKey.Development;
  setGrpcEnv(env);
}

newsServiceMgrNoAuth._setBaseUrlAddon('/bpt.hall.news.v1.noauth')

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,      // 数据在 1 分钟内认为是新鲜的
      retry: 2,                   // 失败后重试 2 次
      refetchOnWindowFocus: false,// 窗口聚焦时不重新请求
      refetchOnMount: true,       // 组件挂载时重新请求
    },
  },
});

const RootElement = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default RootElement;