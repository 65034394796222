import { createAppKit } from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { mttChain } from "./chain/mtt"
import { ethChain } from "./chain/eth"
import { bscChain } from './chain/bsc'
import { AppKitNetwork } from '@reown/appkit/networks'

// 1. Get projectId
const projectId = 'aa94b80fbae49d4579a6b6edd707f93b'

// 2. Set the networks
export const networks: [AppKitNetwork, ...AppKitNetwork[]] = [mttChain, ethChain, bscChain]

// 3. Create a metadata object - optional
const metadata = {
  name: 'MTT Network',
  description: 'MTT Network',
  url: 'https://www.mtt.network/', // origin must match your domain & subdomain
  icons: ['https://sports.mtt.xyz/favicon-192.png'],
}
// 4. Create a AppKit instance
export const modal = createAppKit({
  adapters: [new EthersAdapter()],
  defaultNetwork: mttChain,
  networks,
  metadata,
  projectId,
  allWallets: 'HIDE',
  features: {
    analytics: false,
    socials: false,
    email: false,
    swaps: false,
    onramp: false,
    send: false,
    history: false,
  },
  themeVariables: {
    '--w3m-font-family': ` sans-serif`,
    '--w3m-border-radius-master': '1.75px',
    '--w3m-z-index': 5000,
  },
  featuredWalletIds: [],
  allowUnsupportedChain: true,
})
