import { ethers } from "ethers";
import { networks } from "../initAppkit"

export function formatUnits(value: bigint, decimals: number, unit?: number) {
  const formatted = +ethers.formatUnits(value, decimals || 18);
  return formatted % 1 === 0 ? formatted.toFixed(0) : formatted.toFixed(8).replace(/\.?0+$/, '')
}

export function parseUnits(value: string, decimals: number) {
  return ethers.parseUnits(value, decimals)
}

// 自定义链转换规则
export function transformChain(id: string) {
  const chain = networks.find(network => network.id === Number(id))
  if (!chain) return null
  const chainId = '0x' + Number(id).toString(16)
  return {
    chainId,
    chainName: chain.name,
    rpcUrls: chain.rpcUrls.default.http,
    nativeCurrency: chain.nativeCurrency,
    blockExplorerUrls: chain.blockExplorers?.default.url
  }
}