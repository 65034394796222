import { evmToCosmos } from "@mtt/wallet-cosmos"
import { BrowserProvider, ethers } from "ethers"
import erc20ABI from "@/web3/abi/erc20"
import { MTT_EVM } from "@/web3/consts/chain"
import { formatUnits } from "@/web3/utils"


const osmosisUsdcDenom = "ibc/498A0751C798A0D9A389AA3691123DADA57DAA4FE165D5C75894505B876BA6E4"
export function queryOsmosisBalance(address: string) {
  if (!address) return Promise.resolve("0")
  return fetch(`https://lcd-osmosis.keplr.app/cosmos/bank/v1beta1/balances/${address}`, {
    body: null,
    method: 'GET',
    mode: 'cors',
  }).then((res) => res.json())
  .then(result => {
    const denom = result?.balances?.find?.((bl: any) => bl.denom === osmosisUsdcDenom)
    return formatUnits(denom?.amount || 0, 6)
  })
}

const usdc = "ibc/247DEF7C0E022DC3FAF5EB13A38C616252B5B85651803E03710CCBD581673C87"
export function queryBalance(address: string) {
  if (!address) return Promise.resolve("0")
  const cosmosAddress = evmToCosmos(address)
  return fetch(`https://cosmos-rest.mtt.network/cosmos/bank/v1beta1/balances/${cosmosAddress}/by_denom?denom=${usdc}`, {
    body: null,
    method: 'GET',
    mode: 'cors',
  }).then((res) => res.json())    
}

const tokenAddress = "0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265"
export async function queryMttUsdcBalance(address: string | undefined) {
  if (!address) return Promise.resolve("0")
  try {
    if (typeof window !== 'undefined' && window.ethereum) {
      const provider = new BrowserProvider(window.ethereum as any)
      const network = await provider.getNetwork()
      if (Number(network.chainId) !== MTT_EVM.id) return Promise.resolve("0")
      const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, provider)
      const balance = await tokenContract.balanceOf(address)
      const decimals = await tokenContract.decimals()
      const tokenBalance = (+ethers.formatUnits(balance, decimals)).toString()
      return Promise.resolve(tokenBalance)
    } else {
      return Promise.resolve("0")
    }
  } catch(error) {
    console.error("queryMttUsdcBalance >>", error, address)
    return Promise.resolve("0")
  }
}