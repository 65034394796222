import { evmToCosmos } from '@mtt/wallet-cosmos';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { queryBalance, queryMttUsdcBalance } from '@/utils/bridge';
import { useAppKitAccount } from '@reown/appkit/react';
import { formatUnits } from '@/web3/utils';



type TGlobalContext = {
  address: string | undefined
  cosmosAddr: string
  handleAddress: (addr: string) => void;

  keplrAddress: string
  setKeplrAddress: (addr: string) => void

  usdcBalance: string
  queryUSDC: (addr: string) => void
  evmUsdcBalance: string
  queryEvmUSDC: (addr: string) => void
};

const initialState: TGlobalContext = {
  address: "",
  cosmosAddr: "",
  handleAddress: (addr: string) => {},

  keplrAddress: "",
  setKeplrAddress: (addr: string) => {},

  usdcBalance: "",
  evmUsdcBalance: "",
  queryUSDC: (addr: string) => {},
  queryEvmUSDC: (addr: string) => {}
};

const GlobalContext = createContext<TGlobalContext>(initialState);

type Props = {
  children: React.ReactNode;
};

export function GlobalContextProvider({ children }: Props) {
  const { address, isConnected } = useAppKitAccount()

  // const [address, setAddress] = useState("")
  const [keplrAddress, setKeplrAddress] = useState("")
  const [usdcBalance, setUsdcBalance] = useState("")
  const [evmUsdcBalance, setEvmUsdcBalance] = useState("")

  useEffect(() => {
    if (address && isConnected) {
      console.log("address and isConnected >>", address)
      handleAddress(address)
    }
  }, [address, isConnected])

  const cosmosAddr = useMemo(() => {
    if (!address) return ""
    return evmToCosmos(address)
  }, [address])

  const { run: queryUSDC } = useRequest(async (addr: string) => {
    if (!addr) return ""
    const resp = await queryBalance(addr).catch(err => {
      console.log("queryBalance >>>", err)
      return null
    })
    console.log("queryBalance >>>", resp)
    const balance = formatUnits(resp?.balance?.amount || 0, 6)
    setUsdcBalance(balance)
    return balance
  }, { manual: true })

  const { run: queryEvmUSDC } = useRequest(async (addr: string) => {
    if (!addr) return ""
    const resp = await queryMttUsdcBalance(addr).catch(err => {
      console.log("queryEvmBalance >>>", err)
      return null
    })
    console.log("queryEvmBalance >>>", resp)
    setEvmUsdcBalance(resp || "")
  }, { manual: true })

  const handleAddress = useCallback((addr: string) => {
    if (addr) {
      queryUSDC(addr)
      queryEvmUSDC(addr)
    } else {
      setUsdcBalance("")
    }
  }, [address])

  return (
    <GlobalContext.Provider value={{
      address,
      cosmosAddr,
      handleAddress,
      keplrAddress,
      setKeplrAddress,
      usdcBalance,
      queryUSDC,
      evmUsdcBalance,
      queryEvmUSDC
    }}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}